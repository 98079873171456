import { css } from '@emotion/react';
import { grid } from '@prototyp/gatsby-plugin-gumball/utils';
import { breakpoints, colors, fontFamily } from 'style';

const root = css`
  width: 100%;
  position: absolute;
  z-index: 2;
`;

const hidden = css`
  display: none;
`;

const navWrapper = css`
  padding: var(--spacing-lrg) var(--spacing-xlrg) 0;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media ${breakpoints.large} {
    padding-right: calc(var(--unit) * 18);
    padding-left: calc(var(--unit) * 18);
  }
`;

const contentWrapper = css`
  display: flex;
  align-items: center;
`;

const linkWrapper = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: var(--spacing-lrg);
`;

const link = (isNavGhost?: boolean) => css`
  display: none;

  @media ${breakpoints.large} {
    display: block;
    font-family: var(--font-family-secondary);
    font-size: var(--font-size-samll);
    color: ${isNavGhost ? colors.white : colors.text};
    width: fit-content;
    position: relative;
    border-bottom: 1px solid transparent;
    padding: var(--spacing-med) calc(var(--unit) * 5);

    &:before {
      content: '';
      position: absolute;
      width: 0;
      height: 1px;
      bottom: 4px;
      left: var(--spacing-xlrg);
      background-color: ${isNavGhost ? colors.white : colors.text};
      visibility: hidden;
      transition: all 0.3s ease-in-out;
    }

    &:hover:before {
      visibility: visible;
      width: calc(100% - 40px);
    }
  }
`;

const switcherContainerPrimary = css`
  display: none;

  @media ${breakpoints.large} {
    display: block;
    margin-right: calc(var(--unit) * 6);
  }
`;

const switcherContainerSecondary = css`
  margin-bottom: var(--spacing-xlrg);
`;

const img = css`
  ${grid.colStart.c1}

  @media ${breakpoints.large} {
    ${grid.colStart.c4}
  }
`;

const menuWrapper = css`
  position: absolute;
  top: 0;
  background-color: ${colors.primary};
  width: 100%;
  height: 100%;
  display: flex;
  padding-right: calc(var(--unit) * 18);
  overflow-y: scroll;

  @media ${breakpoints.mediumMax} {
    padding-right: var(--spacing-xlrg);
    padding-left: var(--spacing-xlrg);
  }

  @media ${breakpoints.large} {
    overflow: hidden;
  }
`;

const menuFlex = css`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-top: var(--spacing-lrg);
`;

const navButton = css`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(var(--unit) * 12);
  width: calc(var(--unit) * 12);

  top: var(--spacing-sml);
  right: calc(var(--spacing-sml) + 2px);
  margin-top: var(--spacing-sml);

  z-index: 5;

  border: none;
  border-radius: 50%;
  background-color: ${colors.primary};
  outline: none;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  transform: scale(0.8);

  @media ${breakpoints.medium} {
    top: var(--spacing-med);
  }

  @media ${breakpoints.large} {
    top: var(--spacing-lrg);
    position: absolute;
    height: calc(var(--unit) * 12);
    width: calc(var(--unit) * 12);
    right: calc(var(--unit) * 10);

    background-color: transparent;
    transform: scale(1);
  }

  &:hover {
    background-color: ${colors.secondary};
  }
`;

const menuLinkWrapper = css`
  position: relative;
  ${grid.colStart.c1}
  ${grid.colSpan.c9}
  
  @media ${breakpoints.large} {
    ${grid.colStart.c4}
    margin-top: calc(var(--unit) * 20);
  }
`;

const menuLink = css`
  display: block;
  font-family: ${fontFamily.secondary};
  font-weight: 500;
  font-size: var(--font-size-large);
  line-height: var(--line-height-medium);
  color: ${colors.white};
  padding: var(--spacing-med) 0;
  width: fit-content;
  position: relative;

  @media ${breakpoints.medium} {
    font-weight: var(--font-weight-regular);
    font-size: var(--font-size-xxxLarge);
    line-height: var(--line-height-xxxLarge);
  }

  @media ${breakpoints.large} {
    font-size: var(--font-size-xxLarge);
    line-height: var(--line-height-xxLarge);
  }

  &:before {
    content: '';
    position: absolute;
    width: 0;
    height: 1px;
    bottom: 4px;
    left: 0;
    background-color: ${colors.white};
    visibility: hidden;
    transition: all 0.3s ease-in-out;
  }

  &:hover:before {
    visibility: visible;
    width: 100%;
  }
`;

const menuLinkMobile = css`
  ${menuLink}

  @media ${breakpoints.large} {
    display: none;
  }
`;

const divider = css`
  width: 100%;
  ${grid.colSpan.c9}
  ${grid.colStart.c4}
  opacity: 0.1;
  margin-top: var(--spacing-xxlrg);
`;

const navImg = css`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const copyrightPrimary = css`
  position: absolute;
  margin-left: 5vw;
  bottom: 5vh;
  z-index: 1;

  font-family: var(--font-family-secondary);
  font-weight: var(--font-weight-regular);
  font-size: var(--font-size-small);
  line-height: var(--line-height-small);

  color: ${colors.white};
`;

const copyrightSecondary = css`
  display: none;
  padding: var(--spacing-sml);
  margin-bottom: var(--spacing-xlrg);

  font-family: var(--font-family-secondary);
  font-weight: var(--font-weight-regular);
  font-size: var(--font-size-small);
  line-height: var(--line-height-small);

  color: ${colors.white};

  @media ${breakpoints.mediumMax} {
    display: block;
  }
`;

const copyrightWrapper = css`
  position: absolute;
  left: calc(0px - var(--unit) * 2);
  bottom: calc(0vh - 30vh);

  @media ${breakpoints.large} {
    visibility: hidden;
  }
`;

const imgWrapper = css`
  position: relative;
  max-width: 30%;
  width: 100%;
  height: 100vh;
  margin-right: calc(var(--unit) * 18);

  @media ${breakpoints.mediumMax} {
    display: none;
  }
`;

const mobileWrapper = css`
  width: 100%;
  padding: var(--spacing-lrg) 0;
`;

const logo = (isNavGhost?: boolean) => css`
  fill: ${isNavGhost ? colors.white : colors.text};
  transition: 0.3s ease-out;
  box-sizing: content-box;
  padding: var(--spacing-med) 0;

  &:hover {
    opacity: 0.7;
  }
`;

const logoWrapper = css`
  height: calc(var(--unit) * 5);
  width: calc(var(--unit) * 20);
  padding: var(--spacing-med);
  padding-left: 0;
  box-sizing: content-box;

  @media ${breakpoints.medium} {
    height: calc(var(--unit) * 10);
    width: calc(var(--unit) * 32);
  }

  &:hover {
    opacity: 0.7;
  }
`;

const wrapper = css`
  position: relative;
`;

const active = css`
  .nav-active--primary {
    position: relative;
    font-weight: var(--font-weight-bold);
    color: ${colors.primary};

    &:after {
      content: '';
      position: absolute;
      height: 1px;
      width: calc(100% - 40px);
      bottom: 4px;
      left: var(--spacing-xlrg);
      background-color: ${colors.primary};
      transition: all 0.3s ease-in-out;
    }
  }

  .nav-active--secondary {
    position: relative;

    &:after {
      content: '';
      position: absolute;
      width: 100%;
      height: 1px;
      bottom: 4px;
      left: 0;
      background-color: ${colors.white};
      transition: all 0.3s ease-in-out;
    }
  }
`;

export const nav = {
  root,
  active,
  navWrapper,
  contentWrapper,
  linkWrapper,
  link,
  img,
  imgWrapper,
  copyrightPrimary,
  copyrightSecondary,
  copyrightWrapper,
  menuWrapper,
  menuFlex,
  navButton,
  menuLinkWrapper,
  menuLink,
  menuLinkMobile,
  divider,
  navImg,
  mobileWrapper,
  logo,
  logoWrapper,
  hidden,
  wrapper,
  switcherContainerPrimary,
  switcherContainerSecondary,
};
